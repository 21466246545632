export default {
  'wishlist_buttons': {
    'add_product': 'Add to Wishlist',
    'remove_product': 'Remove from Wishlist',
    'product_in_wishlist': 'In Wishlist',
    'view_wishlist': 'View wishlist',
    'clear_wishlist': 'Clear wishlist',
    'wishlist': 'Wishlist',
    'save_for_later': 'Save for later',
  },
  'wishlist_page': {
    'title': 'Wishlist',
    'login_callout_html': 'To save your wishlist please <a href=\'{{ login_url }}\'>login</a> or <a href=\'{{ register_url }}\'>sign up</a>.',
    'wishlist_empty_callout_html': 'Your wishlist is empty',
    'wishlist_empty_cta': 'Discover more',
    'product_removed_html': 'Product no longer available',
    'view_product': 'View product',
    'remove_product': 'Remove',
    'add_all_to_cart': 'Add All Available Items to Cart',
  },
  'wishlist_product': {
    'add_to_cart': 'Add to Cart',
    'select_option': 'Select {{ name }}',
    'sold_out': 'Sold Out',
    'unavailable': 'Unavailable',
    'view_product': 'View product',
    'from_price_html': 'From {{ price }}',
    'quantity': 'Quantity',
  },
  'wishlist_share': {
    'button_label': 'Share wishlist',
    'link_copied': 'Link copied',
    'share_title': 'My Wishlist',
    'share_message': 'Here is a link to my wishlist.',
  },
};